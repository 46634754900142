<template>
    <div v-editable="blok" :style="styles" class="w-full bg-cover bg-center">
        <div
            class="mx-auto px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32 place-items-center max-w-7xl"
        >
            <StoryblokComponent
                v-for="blok in blok.bloks"
                :key="blok._uid"
                :blok="blok"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    blok: Object,
})

const styles = computed(() => {
    let style = `background-color: ${props.blok.background_color};`
    if (props.blok.background_image?.filename) {
        style =
            style +
            `background-image: url('${props.blok.background_image.filename}');`
    }
    return style
})
</script>
